import { Avatar, Grid, Typography, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React from "react";
import { isIframe, parseHtml } from "../../../../utilities/ParseHtmlToText";
import htmlToFormattedText from "html-to-formatted-text";
import { openIcon } from "../../../../assets";
import { isImage } from "../../../../utilities/isImage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/ar";
import { isLabelMatched } from "../../../../utilities/isLabelMatched";
import { nanoid } from "nanoid";
import { differenceInDays, formatDistanceToNow } from "date-fns";
import { arDZ, fr } from "date-fns/locale";

const ConversationCard = React.memo(
  ({
    item,
    activeConversationCard,
    onClick,
    user,
    style,
    archivedConversation,
  }) => {
    const { t } = useTranslation();
    moment.locale(localStorage.getItem("i18nextLng"));
    if (item?.lastMessage?.from === "BOT") {
      if (item?.lastMessage?.question?.deleted) {
        item = {
          ...item,
          lastMessage: {
            ...item?.lastMessage,
            content: t("Bot message has been deleted"),
            createdAt: item?.lastMessage?.question?.deletedAt,
          },
        };
      } else if (
        item?.meta &&
        isLabelMatched(item?.meta, item?.lastMessage?.question?.label)
      ) {
        item = {
          ...item,
          lastMessage: {
            _id: nanoid(),
            content: item?.meta[`${item?.lastMessage?.question?.label}`],
            createdAt: item?.lastMessage?.createdAt,
            conversation: item?.lastMessage?.conversation,
            from: "CLIENT",
          },
        };
      } else {
        item = {
          ...item,
          lastMessage: {
            ...item?.lastMessage,
            content: item?.lastMessage?.question
              ? item?.lastMessage?.question?.content
              : item?.lastMessage?.content,
          },
        };
      }
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
      "& .MuiBadge-badge": {
        backgroundColor: item?.user_status
          ? item.user_status === "online" || item.user_status === "active"
            ? "#44b700"
            : item.user_status === "away"
            ? "#F9D114"
            : "transparent"
          : (new Date(item?.userAvailability?.lastOnline) >
              new Date(item?.userAvailability?.lastOffline) &&
              new Date(Date.now()) >
                new Date(item?.userAvailability?.lastOnline)) ||
            (item?.userAvailability?.lastOffline === null &&
              new Date(Date.now()) >
                new Date(item?.userAvailability?.lastOnline)) ||
            (item?.userAvailability?.lastOffline === null &&
              item?.userAvailability?.lastOnline === null)
          ? "#44b700"
          : "transparent",

        color: item?.user_status
          ? item.user_status === "online" || item.user_status === "active"
            ? "#44b700"
            : item.user_status === "away"
            ? "#F9D114"
            : "transparent"
          : (new Date(item?.userAvailability?.lastOnline) >
              new Date(item?.userAvailability?.lastOffline) &&
              new Date(Date.now()) >
                new Date(item?.userAvailability?.lastOnline)) ||
            (item?.userAvailability?.lastOffline === null &&
              new Date(Date.now()) >
                new Date(item?.userAvailability?.lastOnline))
          ? "#44b700"
          : "transparent",

        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",

          content: '""',
        },
      },
    }));

    let description = "";
    if (parseHtml(item?.lastMessage?.content)?.includes("+?+?+")) {
      htmlToFormattedText(parseHtml(item.lastMessage?.content))
        .split("+?+?+")
        .map((item) => {
          if (isImage(item)) {
            description = description;
          } else {
            description = description + item;
          }
          return description;
        });
    } else if (isIframe(item?.lastMessage?.content)?.includes("#?#?#")) {
      htmlToFormattedText(isIframe(item.lastMessage?.content))
        .split("#?#?#")
        .map((item) => {
          if (item.includes("https")) {
            description = description;
          } else {
            description = description + item;
          }
          return description;
        });
    }
    const { i18n } = useTranslation();
    return (
      <div
        style={{ ...style }}
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className={
          item?._id === activeConversationCard
            ? "discussion-wrapper active"
            : "discussion-wrapper"
        }
      >
        {item.unread !== 0 && (
          <div
            className="unread-message-number-badge"
            style={{
              left:
                archivedConversation && i18n.language === "ar"
                  ? "45px"
                  : i18n.language === "ar" && "55px",
              right:
                archivedConversation && i18n.language !== "ar"
                  ? "45px"
                  : i18n.language !== "ar" && "55px",
              top: archivedConversation && "38px",
            }}
          >
            <span>{item.unread > 9 ? "+9" : item.unread}</span>
          </div>
        )}

        <Grid
          container
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
          className={`${
            archivedConversation
              ? (() => {
                  return activeConversationCard === item?._id
                    ? "archived-discussion-active-container"
                    : "archived-discussion-container";
                })()
              : activeConversationCard !== item?._id &&
                (item?.lastMessage?.from !== `OPERATOR` || item?.unread > 0)
              ? `discussion-responded-by-takichat`
              : activeConversationCard === item?._id
              ? "discussion-active-container"
              : "discussion-container"
          } ${
            item?.status === "active"
              ? "discussion-container-unresolved"
              : item?.status === "resolved"
              ? "discussion-container-resolved"
              : "discussion-container-pending"
          }`}
          style={{
            left: i18n.language === "ar" && "0 !important",
            right: i18n.language !== "ar" && "0 !important",
            margin: archivedConversation && 0,
          }}
        >
          <Grid
            container
            onClick={onClick}
            item
            xs={archivedConversation ? 12 : 11}
            className="discussion-item"
          >
            <Grid item xs={2}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  sx={{ width: "41.22px", height: "41px" }}
                  src={item?.image}
                />
              </StyledBadge>
            </Grid>
            <Grid item xs={10}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid
                  item
                  xs={
                    "il y a quelques secondes" ===
                    moment(item?.lastMessage?.createdAt).fromNow()
                      ? 4
                      : [
                          "dans quelques secondes",
                          "a few seconds ago",
                        ].includes(
                          moment(item?.lastMessage?.createdAt).fromNow()
                        )
                      ? 6
                      : 7
                  }
                >
                  <Typography className="client-name">
                    {`${item.meta?.name ? item.meta?.name : "Guest"}`}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <span style={{ fontSize: "13px" }}>
                    {item?.isBanned && "🚫"}
                  </span>
                </Grid>

                <Grid
                  item
                  xs={
                    "moins d’une minute" ===
                    formatDistanceToNow(
                      new Date(item?.lastMessage?.createdAt),
                      {
                        locale:
                          i18n.language === "fr"
                            ? fr
                            : i18n.language === "ar"
                            ? arDZ
                            : undefined,
                      }
                    )
                      ? 6
                      : "less than a minute" ===
                        formatDistanceToNow(
                          new Date(item?.lastMessage?.createdAt),
                          {
                            locale:
                              i18n.language === "fr"
                                ? fr
                                : i18n.language === "ar"
                                ? arDZ
                                : undefined,
                          }
                        )
                      ? 5
                      : 4
                  }
                >
                  <Typography className="time-message">
                    {differenceInDays(
                      new Date(),
                      new Date(item?.lastMessage?.createdAt)
                    ) > 5
                      ? `${new Date(
                          item?.lastMessage?.createdAt
                        ).toLocaleDateString(undefined)}`
                      : "less than a minute" ===
                        formatDistanceToNow(
                          new Date(item?.lastMessage?.createdAt),
                          {
                            locale:
                              i18n.language === "fr"
                                ? fr
                                : i18n.language === "ar"
                                ? arDZ
                                : undefined,
                          }
                        )
                      ? "a few seconds"
                      : formatDistanceToNow(
                          new Date(item?.lastMessage?.createdAt),
                          {
                            locale:
                              i18n.language === "fr"
                                ? fr
                                : i18n.language === "ar"
                                ? arDZ
                                : undefined,
                          }
                        ).match(/^About (.+)$/i)
                      ? formatDistanceToNow(
                          new Date(item?.lastMessage?.createdAt),
                          {
                            locale:
                              i18n.language === "fr"
                                ? fr
                                : i18n.language === "ar"
                                ? arDZ
                                : undefined,
                          }
                        ).match(/^About (.+)$/i)[1]
                      : formatDistanceToNow(
                          new Date(item?.lastMessage?.createdAt),
                          {
                            locale:
                              i18n.language === "fr"
                                ? fr
                                : i18n.language === "ar"
                                ? arDZ
                                : undefined,
                          }
                        ).match(/^Environ (.+)$/i)
                      ? formatDistanceToNow(
                          new Date(item?.lastMessage?.createdAt),
                          {
                            locale:
                              i18n.language === "fr"
                                ? fr
                                : i18n.language === "ar"
                                ? arDZ
                                : undefined,
                          }
                        ).match(/^Environ (.+)$/i)[1]
                      : formatDistanceToNow(
                          new Date(item?.lastMessage?.createdAt),
                          {
                            locale:
                              i18n.language === "fr"
                                ? fr
                                : i18n.language === "ar"
                                ? arDZ
                                : undefined,
                          }
                        )}
                  </Typography>
                </Grid>
              </Grid>
              <div
                style={
                  i18n.language === "ar"
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                      }
                }
                dir="ltr"
              >
                <div
                  className="text-message"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  style={{
                    color: item?.unread !== 0 && "#000",
                  }}
                >
                  {!item?.lastMessage?.file
                    ? htmlToFormattedText(parseHtml(item.lastMessage?.content))
                      ? htmlToFormattedText(
                          parseHtml(item?.lastMessage?.content)
                        ).includes("+?+?+")
                        ? description
                        : htmlToFormattedText(
                            isIframe(item?.lastMessage?.content)
                          ).includes("#?#?#")
                        ? description
                        : htmlToFormattedText(item?.lastMessage?.content)
                      : item?.lastMessage?.file && "Sent a file"
                    : "Sent a file"}

                  {description?.length === 0 &&
                  htmlToFormattedText(
                    parseHtml(item?.lastMessage?.content)
                  ).includes("+?+?+")
                    ? "Sent a file"
                    : description?.length === 0 &&
                      htmlToFormattedText(
                        isIframe(item?.lastMessage?.content)
                      ).includes("#?#?#") &&
                      "Sent a file"}
                </div>
                <div>
                  {item?.assigned?.user && (
                    <Avatar
                      src={item?.assigned?.user?.avatar}
                      alt="assigned-avatar"
                      className="assigned-avatar"
                    />
                  )}{" "}
                </div>
              </div>
            </Grid>
          </Grid>
          {!archivedConversation && (
            <Grid item xs={1} className="discussion-new-tab">
              <a
                href={`/messaging/${user?.websiteID}/${item?._id}`}
                target="_blank"
              >
                <LazyLoadImage src={openIcon} alt="open-new-tab" width="15px" />
              </a>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
);

export default ConversationCard;
